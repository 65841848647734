import React from "react";
import { MapContainer, TileLayer, Marker } from "react-leaflet";

const WeddingMap = ({ deviceWidth }) => {
  return (
    <div>
      <MapContainer
        center={[22.3269521, 70.7411076]}
        zoom={13}
        style={{
          height: "30vh",
          width:
            deviceWidth <= 320
              ? "35vh"
              : deviceWidth <= 430
                ? "40vh"
                : deviceWidth <= 1024
                  ? "50vh"
                  : deviceWidth > 1024
                    ? "100vh" : "100vh"

        }}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker position={[22.3269521, 70.7411076]} />
      </MapContainer>
    </div>
  );
};

export default WeddingMap;
