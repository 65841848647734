import React from 'react';
import "./popup.css";

const InvitationPopup = ({ onClose, onOpenInvitation }) => {
  return (
    <div className="popup">
      <div className="popup-content">
        <p>
          💖 You'r Invited! 💖<br />
          We're getting married and would love to celebrate with you.
        </p>
        <button onClick={onOpenInvitation}>Open Invitation</button>
      </div>
    </div>
  );
};

export default InvitationPopup;
