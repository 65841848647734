import React, { useState } from 'react';
import Home from './pages/Home';
import InvitationPopup from './components/InvitationPopup';

function App() {
  const [showPopup, setShowPopup] = useState(true);

  const handleOpenInvitation = () => {
    setShowPopup(false);
  };

  return (
    <>
      {showPopup && (
        <InvitationPopup
          onOpenInvitation={handleOpenInvitation}
        />
      )}
      {!showPopup && <Home />}
    </>
  );
}

export default App;
