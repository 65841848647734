import React, { useState, useEffect } from "react";
import "./home.css";
import "./counter.css";
import Snowfall from "react-snowfall";
import WeddingMap from "../components/WeddingMap";

const currentDeviceWidth = window.innerWidth;

const Home = () => {
  const [deviceWidth, setDeviceWidth] = useState(currentDeviceWidth);
  const [countdownTime, setCountdownTime] = useState(calculateCountdownTime());

  function calculateCountdownTime() {
    const weddingDate = new Date("February 12, 2024 00:00:00 GMT+0000");
    const remainingTime = Math.max(
      0,
      Math.floor((weddingDate - new Date()) / 1000)
    );
    return remainingTime;
  }

  useEffect(() => {
    setDeviceWidth(currentDeviceWidth);
    const audioElement = document.getElementById("my_audio");
    if (audioElement) {
      audioElement.play().catch((error) => {
        console.error("Failed to play audio:", error);
      });
    }

    const intervalId = setInterval(() => {
      setCountdownTime((prevTime) => Math.max(0, prevTime - 1));
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  function getDaysText(days) {
    return days === 1 ? `${days} Day` : `${days} Days`;
  }

  function getHoursText(hours) {
    return hours === 1 ? `${hours} Hour` : `${hours} Hours`;
  }

  function getMinutesText(minutes) {
    return minutes === 1 ? `${minutes} Minute` : `${minutes} Minutes`;
  }

  return (
    <>
      <Snowfall color="#a283e6" />
      <div>
        <div className="sakura-falling"></div>
        <img
          src="https://i.imgur.com/dGOOfnA.png"
          alt="top-right"
          className="top-right-decoration"
        />
        <img
          src="https://i.imgur.com/t6ffnbn.png"
          alt="top-left"
          className="top-left-decoration"
        />
        <section id="media"></section>
        <div className="wrap">
          <div className="title">
            <p>
              <b>।।ॐ श्री गणेशाय नम:।। </b>
            </p>
            <h1>Milan</h1>
            <h2>❤️ &nbsp;&nbsp; and &nbsp;&nbsp; ❤️</h2>
            <h1>Asha</h1>
            <h3 style={{ fontSize: "30pt", fontWeight: "700" }}>
              Are getting married
            </h3>
            <div>
              <span style={{ fontSize: "18pt" }}>on </span>
              <span className="date" style={{ fontSize: "18pt" }}>
                12 February 2024
              </span>
            </div>

            <div className="body2">
              {Array(10)
                .fill(null)
                .map((_, index) => {
                  return (
                    <svg
                      width="50"
                      height="50"
                      viewBox="0 0 200 200"
                      key={index}
                    >
                      <g transform="translate(100 100)">
                        <path
                          transform="translate(-50 -50)"
                          fill="tomato"
                          d="M92.71,7.27L92.71,7.27c-9.71-9.69-25.46-9.69-35.18,0L50,14.79l-7.54-7.52C32.75-2.42,17-2.42,7.29,7.27v0 c-9.71,9.69-9.71,25.41,0,35.1L50,85l42.71-42.63C102.43,32.68,102.43,16.96,92.71,7.27z"
                        ></path>
                        <animateTransform
                          attributeName="transform"
                          type="scale"
                          values="1; 1.5; 1.25; 1.5; 1.5; 1;"
                          dur="1s"
                          repeatCount="indefinite"
                          additive="sum"
                        ></animateTransform>
                      </g>
                    </svg>
                  );
                })}
            </div>
          </div>
        </div>
        {/* <div className="actions">
        <a
          href="https://photos.app.goo.gl/Wn9PRK7FMpxBKF5s6"
          target="_blank"
          rel="noreferrer"
        >
          <div className="venue">👩‍❤️‍👨 See The Best Captured Moments 📷</div>
        </a>
      </div> */}
        <br />
        {/* <div id="time"></div> */}
        <p className="dance-med">Witness the magic of love as we exchange vows in a heartfelt wedding celebration!</p>
        <div
          className="actions"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <a
            href="https://www.paperlesspost.com/go/JNn2B6GC6Wlr6jCNnMaZX/pp_g/preview80605ed8769dfd6d9c275677834024c4?utm_content=view_card&utm_source=cof&utm_medium=email"
            target="_blank"
            rel="noreferrer"
          >
            <div className="venue">OPEN INVITATION CARD</div>
          </a>
        </div>
        <div className="footer">
          <p style={{ fontSize: "14pt", fontWeight: "700" }}>
            Can&apos;t wait to celebrate auspicious moment of our family with
            you! <br />
            {/* Ping me for queries: */}
            {/* <br /> +91 70415 11164
            <span>
              {" "}
              <a
                href="https://api.whatsapp.com/send?phone=7041511164&utm_source=Messaging&utm_medium=Whatsapp"
                target="_blank"
                className="social-icon"
                title="WhatsApp"
                rel="noreferrer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="22"
                  viewBox="0 -8 30 30"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-activity"
                  style={{ marginBottom: "12px" }}
                >
                  <path d="M21 11.5a8.38 8.38 0 0 1-.9 3.8 8.5 8.5 0 0 1-7.6 4.7 8.38 8.38 0 0 1-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 0 1-.9-3.8 8.5 8.5 0 0 1 4.7-7.6 8.38 8.38 0 0 1 3.8-.9h.5a8.48 8.48 0 0 1 8 8v.5z"></path>
                </svg>
              </a>
            </span> */}
          </p>
        </div>
        <div className="music">
          <audio src="/assets/mp3/asd.mp3" id="my_audio" loop="loop"></audio>
        </div>
        <div
          className="actions"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <a
            href="https://maps.app.goo.gl/Lc63whpsZrymAqfh8"
            target="_blank"
            rel="noreferrer"
          >
            <div className="venue">GET DIRECTION</div>
          </a>
          {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3690.705973764405!2d70.73853267519607!3d22.32695704192884!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3959c9ee589bc26b%3A0x3bb2c711ac178742!2scommunity%20Hall!5e0!3m2!1sen!2sin!4v1705431285480!5m2!1sen!2sin" width="600" height="450" style={{ border: 0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
        </div>
        <div className="wrapper">
          <section id="section1">
            <div className="item">
              <img src="/assets/img/jkl.gif" alt="" />
            </div>
            <div className="item">
              <img src="/assets/img/mywed.png" alt="" />
            </div>
            <div className="item">
              <img src="/assets/img/cvb.gif" alt="" />
            </div>
            {/* <div className="item">
              <img src="/assets/img/Invitation (2).png" alt="" />
            </div> */}
            <div className="item">
              <img src="/assets/img/bvc.gif" alt="e" />
            </div>
          </section>
        </div>
        <div className="countdown">
          <h2>Countdown to the Wedding</h2>
          <p>
            <span>
              {getDaysText(Math.floor(countdownTime / (24 * 60 * 60)))}
            </span>
            <span>
              {getHoursText(
                Math.floor((countdownTime % (24 * 60 * 60)) / 3600)
              )}
            </span>
          </p>
          <p>
          <span>
              {getMinutesText(Math.floor((countdownTime % 3600) / 60))}
            </span>
            <span>{countdownTime % 60}s</span>
          </p>
        </div>
        <div className="happiness">
          Join us in our happiness!
          <br />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <a
              href="https://twitter.com/sarcastic_sark"
              target="_blank"
              className="twitter"
              rel="noreferrer"
            >
              <i className="fa fa-twitter" />
            </a>
          </div>
        </div>
        <div style={{ textAlign: "center" }}>
          <img
            src="/assets/img/ggg.gif"
            alt=""
            style={{ margin: "0 auto", maxWidth: "50%", display: "block" }}
          />
        </div>
        <span
          className="footer"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            maxWidth: "600px",
            margin: "20px auto",
            fontFamily: 'Arial, Helvetica, Sans-serif',
            fontWeight: 700,
            paddingLeft: 5,
            paddingRight: 5
          }}
        >
          {`With immense joy and gratitude, we invite you to join us in celebrating the union of our hearts in holy
          matrimony. Your presence will truly make our special day complete.`}
        </span>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <blockquote>
            हल्दी है चंदन है रिश्तों का बंधन है ।
            <br />
            पुरा परिवार सहित आपका हार्दिक अभिनंदन है ।।
          </blockquote>
        </div>
        {/* <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "10px",
            maxWidth: "100%", // Ensure the container doesn't exceed its parent width
            overflow: "hidden", // Hide any overflow content
          }}
        >
          <div
            style={{
              position: "relative",
              width: "100%", // Set the width to 100% for responsiveness
              paddingTop: "56.25%", // Maintain a 16:9 aspect ratio (height = width * 9 / 16)
            }}
          >
            <iframe
              title="wedding-location"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3690.705973764405!2d70.73853267519607!3d22.32695704192884!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3959c9ee589bc26b%3A0x3bb2c711ac178742!2scommunity%20Hall!5e0!3m2!1sen!2sin!4v1705431285480!5m2!1sen!2sin"
              width="100%"
              height="100%"
              style={{ position: "absolute", top: 0, left: 0, border: 0 }}
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div> */}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: 10,
          }}
        >
          <WeddingMap deviceWidth={deviceWidth} />
        </div>
        <div
          className="actions"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <a
            href="#top"
            // onClick="pauseAudio()"
          >
            <div className="venue">
              <b>^ TOP ^</b>
            </div>
          </a>
        </div>
        <br />
        <p
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {" "}
          Crafted with lots of love ❤️ by Milan Vala
        </p>
      </div>
    </>
  );
};

export default Home;
